<template>
    <div>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellendus aspernatur nesciunt eveniet?</p>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        }

    }
</script>
